import React from "react";

import TextBox from "@components/TextBox";
import Quote from "@components/Quote";
import Section from "@components/Section";
import BlobImages from "@components/BlobImages";

import * as styles from "./styles.module.css";

const ArtistsBlock = ({ content, backgroundColor, images, quote }) => {
  return (
    <Section
      as="section"
      className={styles.container}
      backgroundColor={backgroundColor?.value}
    >
      <TextBox {...content} whiteBox={false} className={styles.contentBox} />

      <BlobImages images={images} />

      <Quote className={styles.quoteBox} {...quote} />
    </Section>
  );
};

export default ArtistsBlock;
