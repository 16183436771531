// extracted by mini-css-extract-plugin
export var sizes = "\"@styles/variables.css\"";
export var mediumScreen = "48rem";
export var largeScreen = "59rem";
export var slider = "styles-module--slider--3dhSp";
export var sliderNav = "styles-module--sliderNav--39EkW";
export var dots = "styles-module--dots--1HeuV";
export var dot = "styles-module--dot--63ji3";
export var dotActive = "styles-module--dotActive--7TS8J";
export var arrow = "styles-module--arrow--18H7D";
export var arrowDisabled = "styles-module--arrowDisabled--1vfXS";