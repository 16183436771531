import React from "react";
import cx from "classnames";

import * as styles from "./styles.module.css";

const SliderNav = ({ slider, currentSlide }) => (
  <div className={styles.sliderNav}>
    <Arrow
      direction="left"
      onClick={e => e.stopPropagation() || slider.current?.prev()}
      disabled={currentSlide === 0}
    />
    <Dots slider={slider} currentSlide={currentSlide} />

    <Arrow
      direction="right"
      onClick={e => e.stopPropagation() || slider.current?.next()}
      disabled={
        currentSlide === slider.current?.track?.details?.slides?.length - 1
      }
    />
  </div>
);

const Arrow = props => {
  const disabled = props.disabled && styles.arrowDisabled;

  return (
    <svg
      onClick={props.onClick}
      className={cx(disabled, styles.arrow)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 70"
    >
      <circle cx="35" cy="35" r="35" />
      {props.direction === "left" && (
        <path
          fill="#fefefe"
          d="M34.8,43.7a1.35,1.35,0,0,1-2,0L25,35.9a1.42,1.42,0,0,1,0-2l7.8-7.8a1.35,1.35,0,0,1,2,0l1,1a1.34,1.34,0,0,1,0,2L32,32.76H44.8a1.43,1.43,0,0,1,1.44,1.44v1.38A1.43,1.43,0,0,1,44.8,37H32l3.75,3.74a1.35,1.35,0,0,1,0,2Z"
        />
      )}
      {props.direction === "right" && (
        <path
          fill="#fefefe"
          d="M35.08,42.72a1.35,1.35,0,0,1,0-2L38.83,37H26.06a1.43,1.43,0,0,1-1.44-1.44V34.2a1.43,1.43,0,0,1,1.44-1.44H38.83L35.08,29a1.34,1.34,0,0,1,0-2l1-1a1.35,1.35,0,0,1,2,0l7.8,7.8a1.42,1.42,0,0,1,0,2L38,43.7a1.35,1.35,0,0,1-2,0Z"
        />
      )}
    </svg>
  );
};

const Dots = props => (
  <div className={styles.dots}>
    {[
      ...Array(props.slider.current?.track?.details?.slides?.length).keys(),
    ].map(index => {
      return (
        <button
          aria-label="Go to image in slider"
          key={index}
          onClick={() => {
            props.slider.current?.moveToIdx(index);
          }}
          className={cx(
            styles.dot,
            props.currentSlide === index && styles.dotActive,
          )}
        />
      );
    })}
  </div>
);

export default SliderNav;
