import React from "react";

import TextBox from "@components/TextBox";
import AppStoreButtons from "@components/AppStoreButtons";
import Section from "@components/Section";

import * as styles from "./styles.module.css";

const DevelopmentBlock = ({ content, backgroundColor }) => {
  return (
    <Section
      as="section"
      className={styles.container}
      backgroundColor={backgroundColor?.value}
    >
      <TextBox {...content} whiteBox={false} className={styles.box} />

      <AppStoreButtons className={styles.buttonsWrapper} />
    </Section>
  );
};

export default DevelopmentBlock;
