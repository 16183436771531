import React from "react";

import { convertBgImageToSources } from "@utils/templateHelpers";

import TextBox from "@components/TextBox";
import Slider from "@components/Slider";
import Section from "@components/Section";

import * as styles from "./styles.module.css";

const ProductBlock = ({ content, backgroundImage, images }) => {
  const sources = convertBgImageToSources(
    backgroundImage,
    "(min-width: 768px)",
  );

  return (
    <Section noPadding backgroundImageSources={sources}>
      <div className={styles.wrapper}>
        <TextBox className={styles.box} {...content} />
      </div>

      <Slider className={styles.slider} content={images} />
    </Section>
  );
};

export default ProductBlock;
