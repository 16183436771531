import React, { useEffect, useState } from "react";
import cx from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import "keen-slider/keen-slider.min.css";
import { BREAKPOINTS } from "@utils/breakpoints";
import { useKeenSlider } from "keen-slider/react";
import { getRatio } from "@utils/templateHelpers";

import useResize from "@hooks/useResize";
import useMounted from "@hooks/useMounted";

import SliderNav from "./SliderNav";
import * as styles from "./styles.module.css";

const Slider = ({ className, content }) => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    slides: { origin: "center", perView: "auto", spacing: 70 },
    breakpoints: {
      "(min-width: 1920px)": {
        slides: { origin: "center", perView: "auto", spacing: 100 },
      },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const [isMobile, setIsMobile] = useState(false);
  const isMounted = useMounted();

  useEffect(() => {
    setIsMobile(window.innerWidth >= BREAKPOINTS.large ? false : true);
  }, []);

  useResize(() => {
    setIsMobile(window.innerWidth >= BREAKPOINTS.large ? false : true);
  }, []);

  useEffect(() => {
    slider.current.update();
  }, [isMounted]);

  const smallestRatio = content.reduce((acc, { asset }) => {
    const { gatsbyImageData = {} } = asset;
    const ratio = getRatio(gatsbyImageData);
    return acc ? Math.min(ratio, acc) : ratio;
  }, 0);

  return (
    <div className={cx(className, "navigation-wrapper")}>
      <ul ref={sliderRef} className={cx("keen-slider", styles.slider)}>
        {content.map(({ alt, asset }, index) => (
          <SliderImage
            key={index}
            {...{ alt, asset, index, smallestRatio, isMobile }}
          />
        ))}
      </ul>
      {loaded && slider.current && (
        <SliderNav slider={slider} currentSlide={currentSlide} />
      )}
    </div>
  );
};

const SliderImage = ({ asset, alt, index, smallestRatio, isMobile }) => {
  const { gatsbyImageData = {} } = asset;
  const ratio = getRatio(gatsbyImageData);

  const maxWidth = isMobile ? 90 : 50;
  const width = (smallestRatio / ratio) * maxWidth;

  const imgIndex = index + 1;

  return (
    <li
      className={cx(`keen-slider__slide number-slide${imgIndex}`)}
      style={{ maxWidth: `${width}vw`, minWidth: `${width}vw` }}
    >
      <figure>
        <GatsbyImage image={asset?.gatsbyImageData} alt={alt} />
      </figure>
    </li>
  );
};

export default Slider;
