import { useState, useEffect } from "react";

const useMounted = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) setMounted(true);
  }, [setMounted, mounted]);

  return mounted;
};

export default useMounted;
