import React from "react";

import TextBox from "@components/TextBox";
import ListBox from "@components/ListBox";
import ArtistsBlock from "@components/ArtistsBlock";
import ProductBlock from "@components/ProductBlock";
import DevelopmentBlock from "@components/DevelopmentBlock";
//import AppStoreButtons from "@components/AppStoreButtons";
import Hero from "@components/Hero";
import Button from "@components/Button";

import * as styles from "./styles.module.css";

const LandingPage = ({
  title,
  backgroundImage,
  features,
  intro,
  artistsBlock,
  productBlock,
  developmentBlock,
}) => {
  return (
    <>
      <Hero
        {...{
          title,
          backgroundImage,
        }}
        
      >
        
        <Button className={styles.buttonsWrapper}><a href="/lastned">Last ned Trall</a></Button>
        <ListBox {...features} className={styles.boxOne} />
        <TextBox {...intro} className={styles.boxTwo} />
        
      </Hero>
        
      <ArtistsBlock {...artistsBlock} />
      <ProductBlock {...productBlock} />
      <DevelopmentBlock {...developmentBlock} />
    </>
  );
};

export default LandingPage;
