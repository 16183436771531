import React from "react";
import cx from "classnames";

import Images from "@components/Images";

import * as styles from "./styles.module.css";

const BlobImages = ({ className, images }) => (
  <div className={cx(className, styles.imageWrapper)}>
    <Images images={images} className={styles.image} />
  </div>
);

export default BlobImages;
