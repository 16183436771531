import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as Check } from "@assets/check.svg";
import BoxWrapper from "@components/BoxWrapper";
import List from "@components/List";

const ListBox = ({ className, whiteBox = true, title, items }) => {
  return (
    <BoxWrapper {...{ title, whiteBox }} className={className}>
      <List items={items} enhancerStart={<Check />} />
    </BoxWrapper>
  );
};

ListBox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  whiteBox: PropTypes.bool,
  items: PropTypes.array,
};

export default ListBox;
