import React from "react";
import { graphql } from "gatsby";

import LandingPage from "@components/LandingPage";

const IndexPage = ({ data }) => {
  const { pageData } = data;

  return <LandingPage {...pageData} />;
};

export const query = graphql`
  query LandingPageQuery {
    pageData: sanityLandingPage {
      id
      title
      backgroundImage {
        ...SanityBackgroundImageFragment
      }
      intro {
        ...SanityTextBoxFragment
      }
      features {
        title
        items
      }
      artistsBlock {
        backgroundColor {
          value
        }
        content {
          ...SanityTextBoxFragment
        }
        images {
          ...SanityBlobImageFragment
        }
        quote {
          author
          text
        }
      }
      developmentBlock {
        backgroundColor {
          value
        }
        content {
          ...SanityTextBoxFragment
        }
      }
      productBlock {
        content {
          ...SanityTextBoxFragment
        }
        backgroundImage {
          ...SanityBackgroundImageFragment
        }
        images {
          alt
          asset {
            id
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    }
  }
`;

export default IndexPage;
